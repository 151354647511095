import { graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"

export default function TOCPage() {
  return (
    <Layout>
      <SEO
        page={{
          title: "Términos y condiciones",
          url: "https://casabi.do/terminos-y-condiciones",
        }}
      />
      <Section>
        <Container>
          <Row>
            <Col sm={10} style={{ margin: `auto` }}>
              <h2>Términos y condiciones para uso del portal web</h2>
              <h3>1. Política de entregas</h3>
              <p>
                Te llevamos nuestros productos a cualquier domicilio dentro de las
                localidades de República Dominicana que mencionamos en la tabla de
                abajo. Solo necesitamos contar con los datos exactos de la
                ubicación. El costo del envío correrá en su totalidad a cargo del
                usuario y dependerá de la ubicación exacta de su domicilio y del
                peso del paquete, conforme se indica en la tabla:
              </p>

              <table>
                <thead>
                  <tr>
                    <th>Localidad</th>
                    <th>Costo por envío de paquetes hasta 15 libras</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Azua</td>
                    <td>RD$ 185.00</td>
                  </tr>
                  <tr>
                    <td>Baní</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Barahona</td>
                    <td>RD$ 215.00</td>
                  </tr>
                  <tr>
                    <td>Bávaro</td>
                    <td>RD$ 135.00</td>
                  </tr>
                  <tr>
                    <td>Boca Chica</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Bonao</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Cabarete</td>
                    <td>RD$ 250.00</td>
                  </tr>
                  <tr>
                    <td>Cotuí</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Dajabón</td>
                    <td>RD$ 285.00</td>
                  </tr>
                  <tr>
                    <td>El Seibo</td>
                    <td>RD$ 195.00</td>
                  </tr>
                  <tr>
                    <td>Hato Mayor</td>
                    <td>RD$ 195.00</td>
                  </tr>
                  <tr>
                    <td>Higüey</td>
                    <td>RD$ 210.00</td>
                  </tr>
                  <tr>
                    <td>La Romana</td>
                    <td>RD$ 195.00</td>
                  </tr>
                  <tr>
                    <td>La Vega</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Las Terrenas</td>
                    <td>RD$ 255.00</td>
                  </tr>
                  <tr>
                    <td>Mao</td>
                    <td>RD$ 250.00</td>
                  </tr>
                  <tr>
                    <td>Moca</td>
                    <td>RD$ 175.00</td>
                  </tr>
                  <tr>
                    <td>Monte Plata</td>
                    <td>RD$ 175.00</td>
                  </tr>
                  <tr>
                    <td>Montecristi</td>
                    <td>RD$ 265.00</td>
                  </tr>
                  <tr>
                    <td>Nagua</td>
                    <td>RD$ 190.00</td>
                  </tr>
                  <tr>
                    <td>Navarrete</td>
                    <td>RD$ 230.00</td>
                  </tr>
                  <tr>
                    <td>Neyba</td>
                    <td>RD$ 260.00</td>
                  </tr>
                  <tr>
                    <td>Puerto Plata</td>
                    <td>RD$ 235.00</td>
                  </tr>
                  <tr>
                    <td>Punta Cana</td>
                    <td>RD$ 270.00</td>
                  </tr>
                  <tr>
                    <td>Cabeza de Toro</td>
                    <td>RD$ 280.00</td>
                  </tr>
                  <tr>
                    <td>Salcedo</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>Samaná</td>
                    <td>RD$ 260.00</td>
                  </tr>
                  <tr>
                    <td>San Cristóbal</td>
                    <td>RD$ 175.00</td>
                  </tr>
                  <tr>
                    <td>San Francisco de Macorís</td>
                    <td>RD$ 180.00</td>
                  </tr>
                  <tr>
                    <td>San José de Ocoa</td>
                    <td>RD$ 195.00</td>
                  </tr>
                  <tr>
                    <td>Santo Domingo</td>
                    <td>RD$ 200.00</td>
                  </tr>
                  <tr>
                    <td>San Juan de la Maguana</td>
                    <td>RD$ 240.00</td>
                  </tr>
                  <tr>
                    <td>San Pedro de Macorís</td>
                    <td>RD$ 190.00</td>
                  </tr>
                  <tr>
                    <td>Santiago</td>
                    <td>RD$ 200.00</td>
                  </tr>
                  <tr>
                    <td>Santiago Rodríguez</td>
                    <td>RD$ 245.00</td>
                  </tr>
                  <tr>
                    <td>Sosúa</td>
                    <td>RD$ 250.00</td>
                  </tr>
                </tbody>
              </table>

              <p>
                Las entregas se efectuarán en un plazo no superior a 4 días (96
                horas) laborables tras la confirmación del pedido en nuestra web,
                dentro del horario disponible entre 8:30 AM y 4:30 PM al usuario o a
                la persona autorizada por éste al momento de colocar su orden. En
                todo caso, se notificará al usuario un tiempo estimado de entrega
                que estará sujeto a la ubicación en la que se vaya a efectuar dicha
                entrega.
              </p>
              <p>
                El costo del envío correrá en su totalidad a cargo del usuario y
                dependerá de la ubicación exacta de su domicilio. En cualquier caso,
                dicho costo no será nunca menor a RD$ 180.00 pesos. Costos
                adicionales aplicarían si la orden pesa más de 15 libras.
              </p>
              <p>
                En todo caso, solo podemos procesar pedidos por una cuantía mínima
                de RD$ 500.00, a la que se agregarán los gastos de transporte, que
                aparecerán claramente reflejados en la factura.
              </p>
              <p>
                Nuestro compromiso es ofrecer productos saludables y de calidad,
                según se describen en nuestra web. Como parte de este, si no estás
                satisfecho, disponemos de una política clara de reclamaciones,
                devoluciones y cambios que te explicamos a continuación.
              </p>
              <h3>
                2. Políticas de cancelación de órdenes, devoluciones, cambios y
                reclamaciones
              </h3>
              <h4>2.1. Cancelación de órdenes</h4>
              <p>
                Siempre que el pedido no haya sido despachado, el usuario podrá en
                cualquier momento solicitar la cancelación de su orden y el
                reembolso por el pago realizado.
              </p>
              <p>
                En tal caso, se hará una restitución de lo pagado descontando los
                gastos bancarios y administrativos (y de transporte si aplican)
                derivados.
              </p>
              <p>
                En caso de que el pedido ya haya sido despachado, solo aplicarán las
                políticas de devoluciones que se explican a continuación.
              </p>
              <h4>2.2 Devoluciones y cambios</h4>
              <p>
                Luego de la entrega al usuario (o a la persona autorizada por este)
                del artículo comprado, el usuario tendrá un plazo de 2 días, para
                devolver o solicitar el cambio del artículo que ha comprado. Para
                devoluciones y cambios, el usuario deberá presentar la factura que
                acredite que el artículo fue adquirido directamente de Alimentos
                Fortuna, S.R.L. a través de la web.
              </p>
              <p>
                En caso de devolución, se aplicará una nota de crédito por el monto
                previamente abonado, descontado los gastos bancarios y
                administrativos, y en ambos casos, los costes de transporte. Si la
                devolución o el cambio se debe a un problema sobre la calidad o
                salubridad del producto, el usuario podrá alternativamente optar por
                el reembolso íntegro del monto abonado.
              </p>
              <p>
                No se podrán devolver o cambiar artículos con envases abiertos o
                parcialmente consumidos, salvo en los casos en que la devolución se
                justifique por problemas de calidad o salubridad del producto.
              </p>
              <h3>3. Información de contacto</h3>
              <p>
                Para cualquier reclamación, devolución, cambio de productos,
                consultas, sugerencias o comentarios en general, se insta al cliente
                a tener en cuenta los plazos indicados en cada una de las secciones
                anteriores y a contactar con nosotros en la dirección de correo
                electrónico info@casabi.do y/o dirigirse a nuestras oficinas
                ubicadas en la siguiente dirección:
              </p>
              <address>
                Alimentos Fortuna, S.R.L. Calle Margarita #5, Nave #1, Res. San
                Benito Santo Domingo DN República Dominicana Teléfono: (809)
                701-3448 www.casabi.do
              </address>
              <h3>4. Políticas sobre privacidad y protección de datos</h3>
              <p>
                En Alimentos Fortuna, S.R.L. estamos comprometidos con la protección
                de los datos personales que nos provee en el entendido de que son
                necesarios para colocar sus órdenes de compra.
              </p>
              <p>
                Luego del ingreso de sus datos personales, los mismos serán
                procesados de manera segura y solo tendrá acceso a ellos el personal
                de Alimentos Fortuna, S.R.L. encargado de efectuar los trámites
                relacionados a sus órdenes (ej., tramitación interna,
                despacho/entrega, contabilidad, etc.), y con el fin de poder
                proveerle un servicio de calidad. Siempre se adoptarán las medidas
                de seguridad necesarias para garantizar que sus datos no sean
                empleados de manera inapropiada o sean accesibles a terceros no
                autorizados ajenos a la relación entre nuestra empresa y el usuario.
                En ningún caso se guardarán informaciones relacionadas con la
                tarjeta de crédito o medio de pago efectuado por los usuarios.
              </p>
              <p>
                Entre los datos personales que nuestro portal web podrá solicitar a
                los fines de la tramitación de cualquier orden se encuentran: nombre
                completo, teléfono, dirección de correo electrónico, domicilio
                exacto, etc. En todo caso, el usuario deberá tener en cuenta que en
                cualquier momento se le podrá instar a proporcionar datos
                adicionales a los fines de poder tramitar de manera correcta
                cualquier orden de compra en lo que respecta a entrega, facturación
                y registro interno dentro de la empresa.
              </p>
              <p>
                El usuario podrá en cualquier momento solicitar la eliminación de
                sus datos personales previa comunicación escrita que deberá ser
                enviada a la siguiente dirección de correo electrónico:
                info@casabi.do
              </p>
              <p>
                En cualquier caso y salvo que medie solicitud del usuario dueño de
                los datos u orden judicial, Alimentos Fortuna, S.R.L. no cederá a,
                ni distribuirá entre terceros bajo ningún concepto (venta,
                transferencia gratuita, etc.) los datos personales provistos por el
                usuario.
              </p>
              <h4>4.1. Datos relacionados a pagos</h4>
              <p>
                Los métodos de pago utilizados por Alimentos Fortuna, S.R.L. son
                servicios de terceros. Estos servicios de terceros (AZUL) cumplen
                con todos los estándares de seguridad y cifrado para mantener tu
                información segura. Los proveedores de estos servicios (AZUL) solo
                utilizarán la información necesaria para completar el proceso
                requerido. También recomendamos leer las Políticas de Privacidad de
                estos proveedores para entender mejor cómo manejan la información
                suministrada.
              </p>
              <h4>4.2. Información sobre cookies</h4>
              <p>
                Las cookies o “galletas” son archivos generados por los sitios web
                autorizados para ello por el usuario que almacenan información
                relacionada a la navegación del usuario en Internet y que facilitan
                su experiencia de navegación. Si son aceptadas por el usuario, las
                cookies podrán guardar la información necesaria para acceder más
                rápidamente a determinados sitios web (por ejemplo, a aquellos que
                requieren del ingreso de datos de cuenta del usuario), para recordar
                las preferencias del usuario en determinados sitios web, y para
                ofrecer el contenido y la publicidad más adecuado considerando la
                ubicación y las preferencias del usuario.
              </p>
              <p>
                Por defecto, nuestra web almacena automáticamente las cookies a los
                fines citados de proveer un mejor servicio. Sin embargo, usted puede
                optar por configurar las cookies en el siguiente enlace, caso en el
                que algunos servicios pueden devenir inutilizables.
              </p>
              <h4>
                4.3. Información sobre cambios y actualizaciones a las políticas de
                privacidad
              </h4>
              <p>
                La presente sección sobre políticas de privacidad está sujeta a
                cambios/actualizaciones con el objetivo de garantizar siempre el
                mejor y más seguro uso de los datos provistos por el usuario,
                siempre en apego a las disposiciones normativas que rigen la materia
                y a las buenas prácticas del sector.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
